import React from "react";
import Layout from "../components/Layout";
import Banner from "../components/Banner";
import Config from "./../data/Data";

import Head from "../components/Head";
import { Contact } from "./../data/SEO";

const PageContact = () => {
  const { siteEmail, numberPhone1, numberPhone2, siteLocation } = Config;

  return (
    <Layout>
      <Head Title={Contact[0].title} Description={Contact[0].description} />

      <Banner
        Title="Contáctenos"
        SubTitle="Por teléfono o en el formulario de contacto"
        Image="assets/images/banner/contact.png"
      />

      <div className="pt-lg-5">
        <div className="container py-5">
          <div className="d-lg-flex mb-5 mx-lg-n3">
            <div className="mb-3 mx-lg-3 w-100 border rounded p-4">
              <div className="h2 mb-3 text-blue border-bottom pb-3 d-inline-block pr-5">
                <i className="fas fa-map-marker-alt"></i>
              </div>
              <h2 className="h5 text-uppercase">Dirección</h2>
              <div>{siteLocation}</div>
            </div>
            <div className="mb-3 mx-lg-3 w-100 border rounded p-4">
              <div className="h2 mb-3 text-blue border-bottom pb-3 d-inline-block pr-5">
                <i className="fas fa-phone-alt"></i>
              </div>
              <h2 className="h5 text-uppercase">Teléfonos</h2>
              <div>
                {numberPhone1}
                <span className="d-block">{numberPhone2}</span>
              </div>
            </div>
            <div className="mb-3 mx-lg-3 w-100 border rounded p-4">
              <div className="h2 mb-3 text-blue border-bottom pb-3 d-inline-block pr-5">
                <i className="fas fa-envelope"></i>
              </div>
              <h2 className="h5 text-uppercase">E-mail</h2>
              <p className="mb-0">
                <a href={`mailto:${siteEmail}`} className="none">
                  {siteEmail}
                </a>
              </p>
            </div>
          </div>

          <div className="row align-items-center justify-content-between">
            <div className="col-lg-7">
              <h1 className="font-lg-h2 h3 mb-4 pb-1">
                Cuéntanos ¿Cómo podemos ayudarte?
              </h1>
              <form action="">
                <div className="row">
                  <div className="col-lg-6">
                    <div className="form-group mb-4">
                      <input
                        type="text"
                        className="form-control form-control-lg"
                        placeholder="Tu nombre y apellido"
                      />
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group mb-4">
                      <input
                        type="text"
                        className="form-control form-control-lg"
                        placeholder="Teléfono o WhatsApp"
                      />
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="form-group mb-4">
                      <input
                        type="text"
                        className="form-control form-control-lg"
                        placeholder="Tu correo electrónico"
                      />
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="form-group mb-4">
                      <textarea
                        name=""
                        className="form-control form-control-lg"
                        cols="30"
                        rows="10"
                        placeholder="Tu mensaje"
                      ></textarea>
                    </div>
                  </div>

                  <div className="col-12">
                    <label for="accept">
                      <input
                        type="checkbox"
                        name=""
                        id="accept"
                        className="mr-2"
                      />
                      He leído y acepto los{" "}
                      <a href="/#" className="text-underline">
                        Términos y Condiciones
                      </a>{" "}
                      y la{" "}
                      <a href="/#" className="text-underline">
                        Política de Privacidad
                      </a>
                    </label>
                  </div>

                  <div className="col-12">
                    <div className="mt-4 pt-1">
                      <button className="btn btn-primary">
                        Enviar mensaje
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>

            <div className="col-lg-4 d-none d-lg-block">
              <img
                src={"assets/images/contact.png"}
                className="mx-auto w-100 d-block"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default PageContact;
