const Year = new Date().getFullYear();

const Config = {
  siteName: "Doous",
  siteLogo: "/assets/images/logo.svg",
  siteLogoFooter: "/assets/images/doous-black.svg",
  siteCopyright: `© ${Year} Doous S.A.C.`,
  siteLegal: "Todos los derechosreservados.",
  siteSlogan: "Soluciones inteligentes",
  numberWhatsapp: "967999095",
  numberPhone1: "(+51) 01 744 7577",
  numberPhone2: "(+51) 01 680 2666",
  siteEmail: "ventas@doous.com",
  siteLocation: "Av. Nicolás Arriola 314 Of 503 B, Santa Catalina, Lima - Perú",
  siteDescription:
    "Creamos soluciones con el objetivo de elevar la eficiencia en los procesos de tu empresa con las últimas tecnologías del mercado.",
  shareLinks: [
    {
      url: "https://www.facebook.com/doousperu",
      iconClassName: "fa-facebook-f",
    },
    {
      url: "https://www.linkedin.com/company/doous/",
      iconClassName: "fa-linkedin-in",
    },
    {
      url: "https://twitter.com/doousperu",
      iconClassName: "fa-twitter",
    },
  ],
};

module.exports = Config;
