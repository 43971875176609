import React from "react";
import Layout from "../components/Layout";
import Banner from "../components/Banner";

import Head from "../components/Head";
import { About } from "./../data/SEO";

const PageAbout = () => {
  return (
    <Layout>
      <Head Title={About[0].title} Description={About[0].description} />

      <Banner
        Title="Nosotros"
        SubTitle="Acerca de Doous"
        Image="assets/images/banner/about.png"
      />

      <div className="pt-lg-5">
        <div className="container text-center py-5">
          <div className="h2 font-weight-normal mb-0 py-4">
            <span className="text-blue">Creamos soluciones</span> para aumentar
            <br className="d-none d-md-block" />
            la eficiencia y eficacia de los procesos de
            <br className="d-none d-md-block" />
            nuestros clientes, para hacerlos crecer
            <br className="d-none d-md-block" />
            de forma ágil y <span className="text-blue">escalable</span>
          </div>

          <div className="pt-5 my-4 pb-2">
            <img
              src={"assets/images/doous-color.svg"}
              className="mx-auto"
              alt=""
            />
          </div>

          <div>
            Tenemos más 5 años de experiencia desarrollando en diferentes
            <br className="d-none d-md-block" />
            industrias y tamaños de negocios, desde startups, pymes,
            <br className="d-none d-md-block" />
            medianas y grandes empresas. Además, disponemos de un
            <br className="d-none d-md-block" />
            Laboratorio de Innovación donde probamos nuevas
            <br className="d-none d-md-block" />
            tecnologías y nuevos productos digitales
          </div>

          <div className="mt-4">
            <strong>
              <em>que tus ideas no queden sin desarrollar</em>
            </strong>
          </div>

          <div className="pt-4">
            <img
              src={"assets/images/developer.png"}
              className="mx-auto"
              alt=""
            />
          </div>

          <div className="my-4">
            Siéntete seguro y con confianza, nuestra experiencia y clientes
            <br className="d-none d-md-block" />
            nos respaldan, trabajamos con cualquier tecnología del mercado.
          </div>

          <div className="pt-4">
            <img
              src={"assets/images/software.svg"}
              className="mx-auto"
              alt=""
            />
          </div>

          <div className="my-4 h5 font-weight-normal">y más...</div>

          <div className="mt-4 pt-1">
            <a href="/contact" className="btn btn-primary">
              Contáctenos
            </a>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default PageAbout;
