import React from "react";
import { Switch, Route } from "react-router-dom";

import PageIndex from "./pages/Index";
import PageAbout from "./pages/About";
import PageLabs from "./pages/Labs";
import PageInvoice from "./pages/Invoice";
import PageConsulting from "./pages/Consulting";
import PageContact from "./pages/Contact";
import Page404 from "./pages/error404";

const Routes = () => {
  return (
    <Switch>
      <Route exact path="/" component={PageIndex} />
      <Route path="/about" component={PageAbout} />
      <Route path="/labs" component={PageLabs} />
      <Route path="/invoice" component={PageInvoice} />
      <Route path="/consulting" component={PageConsulting} />
      <Route path="/contact" component={PageContact} />
      <Route path="*" component={Page404} />
    </Switch>
  );
};

export default Routes;
